import { acceptHMRUpdate, defineStore } from 'pinia';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ComputedRef, Ref, computed, ref } from 'vue';

import { useHttpCache } from '@silae/composables';
import { VariableElementsCommentDTO, VariableElementsCommentsRequest, fetchVariableElementsComments$ } from '~/api';

import { Clearable } from '../store.domain';

export type VariableElementsCommentsStore = Clearable & {
	fetchComments$: (
		companyId: number,
		request: VariableElementsCommentsRequest,
		invalidateCache?: boolean
	) => Observable<Array<VariableElementsCommentDTO>>;
	commentsByEmployeeId: ComputedRef<Record<number, Array<VariableElementsCommentDTO>>>;
	addFromCache: (comment: VariableElementsCommentDTO) => void;
	updateFromCache: (updatedComment: Partial<VariableElementsCommentDTO>) => void;
	removeFromCache: (id: number) => void;
};

export const useVariableElementsCommentsStore = defineStore<'variable-elements-comments', VariableElementsCommentsStore>(
	'variable-elements-comments',
	() => {
		const { cache$: commentsCache$, clearCache: clearCommentsCache } = useHttpCache<string, Array<VariableElementsCommentDTO>>();

		const _comments: Ref<Array<VariableElementsCommentDTO>> = ref([]);

		const commentsByEmployeeId: ComputedRef<Record<number, Array<VariableElementsCommentDTO>>> = computed(() => {
			return _comments.value.reduce((acc, comment) => {
				if (comment) {
					const employeeId = comment.employeeId;

					if (!acc[employeeId]) {
						acc[employeeId] = [];
					}
					acc[employeeId].push(comment);
				}

				return acc;
			}, {});
		});

		const clear = () => {
			clearCommentsCache();
		};

		const removeFromCache = (id: number) => {
			_comments.value = _comments.value.filter(comment => comment.id !== id);
		};

		const addFromCache = (comment: VariableElementsCommentDTO) => {
			_comments.value = [..._comments.value, comment];
		};

		const updateFromCache = (updatedComment: Partial<VariableElementsCommentDTO>) => {
			_comments.value = _comments.value.map(comment =>
				comment.id === updatedComment.id ? { ...comment, ...updatedComment } : comment
			);
		};

		const fetchComments$ = (companyId: number, request: VariableElementsCommentsRequest, invalidateCache?: boolean) => {
			if (invalidateCache) {
				clearCommentsCache();
			}

			return commentsCache$(
				`variable-elements-comments-${companyId}`,
				fetchVariableElementsComments$(companyId, request).pipe(
					tap((comments: Array<VariableElementsCommentDTO>) => (_comments.value = comments))
				)
			);
		};

		return {
			fetchComments$,
			clear,
			commentsByEmployeeId,
			addFromCache,
			updateFromCache,
			removeFromCache
		};
	}
);

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useVariableElementsCommentsStore, import.meta.hot));
