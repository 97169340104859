import { defineStore, storeToRefs } from 'pinia';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ComputedRef, Ref, computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { useHttpCache } from '@silae/composables';
import { AxiosApiError, EmployeeLoginDTO, PreferredEmail, getAllEmployees$, getPreferredEmail$ } from '~/api';
import { useToasts } from '~/composables/messages.composables';
import { EmployeeLogin } from '~/domain';
import { employeeDtoToEmployee } from '~/utils';

import { useCompaniesStore } from '../companies.store';
import { Clearable } from '../store.domain';

export type EmployeeLoginsStore = Clearable & {
	fetchEmployeeLogins$: (companyId: number, invalidateCache?: boolean) => Observable<Array<EmployeeLogin>>;
	employeeLogins: ComputedRef<Array<EmployeeLogin>>;
	fetchPreferredEmail$: (companyId: number, invalidateCache?: boolean) => Observable<PreferredEmail>;
	preferredEmail: ComputedRef<PreferredEmail>;
};

// TODO : faire un refactoring de cette fonctionnalité pour qu'elle soit plus générique
const asEmployeeLogin = (employee: EmployeeLoginDTO): EmployeeLogin => {
	return {
		...employee,
		...employeeDtoToEmployee(employee)
	};
};

export const useEmployeeLoginsStore = defineStore<'employee-logins', EmployeeLoginsStore>('employee-logins', () => {
	const { t } = useI18n();
	const { error } = useToasts();
	const { administeredCompaniesById } = storeToRefs(useCompaniesStore());

	const _employeeLogins: Ref<Array<EmployeeLogin>> = ref([]);
	const employeeLogins = computed(() => _employeeLogins.value);

	const _preferredEmail: Ref<PreferredEmail> = ref();
	const preferredEmail = computed(() => _preferredEmail.value);

	const isAuthorized = (companyId: number): boolean => {
		const hasThisCompany = administeredCompaniesById.value.has(companyId);
		return hasThisCompany ? administeredCompaniesById.value.get(companyId).isAdmin : false;
	};

	const { cache$, clearCache } = useHttpCache<number, Array<EmployeeLoginDTO>>(5000);
	const { cache$: preferredEmailCache$, clearCache: clearPreferredEmailCache } = useHttpCache<number, PreferredEmail>(5000);

	const _fetchEmployeeLogins$ = (companyId: number, invalidateCache?: boolean): Observable<Array<EmployeeLogin>> => {
		if (!isAuthorized(companyId)) {
			return EMPTY;
		}

		if (invalidateCache) {
			clearCache();
		}

		return cache$(companyId, getAllEmployees$(companyId)).pipe(
			catchError((err: AxiosApiError) => {
				error({ title: t('common.feedback.error.title'), text: err.response.data.error });
				return EMPTY;
			}),
			map(employees => employees.map(asEmployeeLogin)),
			tap(employeeLogins => {
				_employeeLogins.value = employeeLogins;
			})
		);
	};

	const _fetchEmailPreference$ = (companyId: number, invalidateCache?: boolean): Observable<PreferredEmail> => {
		if (!isAuthorized(companyId)) {
			return EMPTY;
		}

		if (invalidateCache) {
			clearPreferredEmailCache();
		}

		return preferredEmailCache$(companyId, getPreferredEmail$(companyId)).pipe(
			catchError((err: AxiosApiError) => {
				error({ title: t('common.feedback.error.title'), text: err.response.data.error });
				return EMPTY;
			}),
			tap(preferredEmail => {
				_preferredEmail.value = preferredEmail;
			})
		);
	};

	const clear = () => {
		_employeeLogins.value = [];
		clearCache();
		clearPreferredEmailCache();
	};

	return {
		fetchEmployeeLogins$: _fetchEmployeeLogins$,
		employeeLogins,
		fetchPreferredEmail$: _fetchEmailPreference$,
		preferredEmail,
		clear
	};
});
