import { acceptHMRUpdate, defineStore, storeToRefs } from 'pinia';
import { EMPTY, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ComputedRef, Ref, computed, ref } from 'vue';

import { useHttpCache } from '@silae/composables';
import { ISODateString, fetchCompanyEmployees$ } from '~/api';
import { Employee } from '~/domain';
import { employeeDtoToEmployee } from '~/utils';

import { useCompaniesStore } from '../companies.store';
import { Clearable } from '../store.domain';

export type EmployeesStore = Clearable & {
	employeesByCompany: ComputedRef<Map<number, Array<Employee>>>;
	fetchEmployees$: (companyId: number, basePeriod?: ISODateString, invalidate?: boolean) => Observable<Array<Employee>>;
};

export const useEmployeesStore = defineStore<'employee-store', EmployeesStore>('employee-store', () => {
	const { cache$: cacheEmployee$, clearCache: clearEmployee$ } = useHttpCache<number, Array<Employee>>();
	const { companiesById } = storeToRefs(useCompaniesStore());

	const _employeesByCompany: Ref<Map<number, Array<Employee>>> = ref(new Map());

	const clear = () => {
		_employeesByCompany.value.clear();
		clearEmployee$();
	};

	const isAuthorized = (companyId: number): boolean => {
		const hasThisCompany = companiesById.value.has(companyId);
		return hasThisCompany ? companiesById.value.get(companyId).isAdmin : false;
	};

	const fetchEmployees$ = (companyId: number, basePeriod?: ISODateString, invalidate?: boolean): Observable<Array<Employee>> => {
		if (!isAuthorized(companyId)) {
			return EMPTY;
		}

		if (invalidate) clear();
		return cacheEmployee$(
			companyId,
			fetchCompanyEmployees$(companyId, basePeriod).pipe(
				map(employees => employees.map(employeeDtoToEmployee)),
				tap(employees => {
					_employeesByCompany.value.set(companyId, employees);
				})
			)
		);
	};

	return {
		clear,
		employeesByCompany: computed(() => _employeesByCompany.value),
		fetchEmployees$
	};
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useEmployeesStore, import.meta.hot));
