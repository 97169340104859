import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { defineStore } from 'pinia';
import { PostHog } from 'posthog-js';
import { Ref, ref } from 'vue';

import { debug } from '@silae/helpers';
import { UserDTO } from '~/api';
import { stripQueryParams } from '~/utils';

import { TRACKING_STRIPPED_QUERY_PARAMS } from './tracking.constant';

type TrackingService = {
	clearUserContext: () => void;
	initAppInsights: (appInsights: ApplicationInsights) => void;
	initPostHog: (postHog: PostHog) => void;
	setUserContext: (user: UserDTO) => void;
	setUserProperties: (userProperties: Record<string, any>) => void;
	trackEvent: (event: string, customProperties?: Record<string, any>) => void;
	trackPageView: () => void;
};

/***
 	You should know that AdBlocker manages mostly to prevent tracking calls, and it can create some errors.
 */
export const useTrackingService = defineStore<'tracking-service', TrackingService>('tracking-service', () => {
	const _appInsights: Ref<ApplicationInsights> = ref();
	const _postHog: Ref<PostHog> = ref();

	const initPostHog = (postHog: PostHog) => (_postHog.value = postHog);

	const initAppInsights = (appInsight: ApplicationInsights) => (_appInsights.value = appInsight);

	const setUserContext = (user: UserDTO) => {
		const authenticatedUserId = user.login?.toLowerCase();
		_appInsights.value?.setAuthenticatedUserContext(authenticatedUserId);
		_postHog.value?.identify(authenticatedUserId, {
			email: authenticatedUserId,
			firstname: user.firstname,
			lastname: user.lastname,
			companies: user.companies
		});
	};

	const setUserProperties = (customProperties: Record<string, any>) => {
		_postHog.value?.setPersonProperties(customProperties);
	};

	const clearUserContext = () => {
		_appInsights.value?.clearAuthenticatedUserContext();
		_postHog.value?.reset();
	};

	const trackEvent = (event: string, props?: Record<string, any>) => {
		try {
			// remove JWT from URL sent to PostHog also
			const $current_url = stripQueryParams(window.location.href, TRACKING_STRIPPED_QUERY_PARAMS);
			_postHog.value?.capture(event, { ...props, $current_url });
		} catch (err: unknown) {
			// add blocker can prevent tracking or create an error
			// TODO we should probably catch this using Sentry
			debug('tracking failed');
		}
	};

	const trackPageView = () => {
		try {
			// trigger post hog auto capture as we're in an SPA
			// remove JWT from URL sent to PostHog
			// $current_url overrides the URL automatically sniffed by PostHog
			const $current_url = stripQueryParams(window.location.href, TRACKING_STRIPPED_QUERY_PARAMS);
			_postHog.value?.capture('$pageview', { $current_url });
		} catch (err: unknown) {
			// add blocker can prevent tracking or create an error
			// TODO we should probably catch this using Sentry
			debug('tracking failed');
		}
	};

	return { clearUserContext, initAppInsights, initPostHog, setUserContext, setUserProperties, trackEvent, trackPageView };
});
