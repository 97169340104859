import { IconAliases } from 'vuetify';
import { fa } from 'vuetify/iconsets/fa-svg';

import { $fa } from '../icons';

export const vuetifyIconAliases: IconAliases = {
	arrowRightArrowLeft: $fa('arrow-right-arrow-left'),
	autoAddedFrame: $fa('auto-added-frame'),
	assignGroup: $fa('assign-group'),
	calendar: $fa('calendar'),
	cancel: $fa('cancel'),
	checkboxIndeterminate: $fa('square-minus'),
	checkboxOff: $fa('square'),
	checkboxOn: $fa('square-check'),
	clear: $fa('close'),
	close: $fa('close'),
	circleCheck: $fa('circle-check'),
	circleInfo: $fa('circle-info'),
	circlePlus: $fa('circle-plus'),
	collapse: $fa('arrow-up'),
	complete: $fa('check'),
	delete: $fa('bin'),
	delimiter: $fa('horizontal-rule'),
	download: $fa('download'),
	dropdown: $fa('arrow-down'),
	edit: $fa('pen'),
	ellipsisVertical: $fa('ellipsis-vertical'),
	error: $fa('remove-full'),
	expand: $fa('chevron-down'),
	file: $fa('file'),
	files: $fa('files'),
	first: $fa('backward-fast'),
	import: $fa('import'),
	info: $fa('info-full'),
	kebabMenu: $fa('kebab-menu'),
	last: $fa('forward-fast'),
	loading: $fa('spinner-third'),
	meeting: $fa('meeting'),
	menu: $fa('menu'),
	minus: $fa('minus'),
	next: $fa('arrow-right'),
	pen: $fa('pen'),
	plus: $fa('plus'),
	prev: $fa('arrow-left'),
	radioOff: $fa('circle'),
	radioOn: $fa('radio-on'),
	ratingEmpty: $fa('rating-empty'),
	ratingFull: $fa('star'),
	ratingHalf: $fa('star-half'),
	refresh: $fa('rotate'),
	remove: $fa('remove'),
	show: $fa('show'),
	sort: $fa('sort'),
	sortAsc: $fa('down-arrow'),
	sortDesc: $fa('up-arrow'),
	subgroup: $fa('list-tree'),
	success: $fa('validate-full'),
	unfold: $fa('arrow-down'),
	warning: $fa('warning-full')
};

export const vuetifyIcons = {
	defaultSet: 'fa',
	aliases: vuetifyIconAliases,
	sets: {
		fa
	}
};
