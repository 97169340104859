import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { useFeatures } from '~/composables';
import { defaultLayoutComponents } from '~/pages/layout';
import { Devices } from '~/plugins';
import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';

import { VariableElementType } from '../variable-elements';

export const PayrollValidationLeaveDaysRoute: RouteRecordRaw = {
	name: RouteKeys.VARIABLE_ELEMENTS_VALIDATION_LEAVE_DAYS,
	path: 'leave-days',
	component: () => import('./leave-days-validation.vue'),
	meta: {
		isForbidden() {
			const { isAdmin } = storeToRefs(useRolesStore());
			const features = useFeatures();
			return computed(() => Devices().isMobile || !isAdmin.value || !features.hasLeaveDayAdminFullFeature.value);
		}
	}
};

export const PayrollValidationEarnedWageAccessRoute: RouteRecordRaw = {
	name: RouteKeys.VARIABLE_ELEMENTS_VALIDATION_EARNED_WAGE_ACCESS,
	path: 'earned-wage-access',
	component: () => import('./earned-wage-access-validation.vue'),
	meta: {
		isForbidden() {
			const { isAdmin } = storeToRefs(useRolesStore());
			const features = useFeatures();
			return computed(() => Devices().isMobile || !isAdmin.value || !features.hasEarnedWageAccessFeature.value);
		}
	}
};

export const VariableElementsReadonlyRoute: RouteRecordRaw = {
	name: RouteKeys.VARIABLE_ELEMENTS_VALIDATION_READONLY,
	path: ':type?',
	component: () => import('./variable-elements-validation.vue'),
	props: route => ({ type: route.params.type })
};

export const PayrollValidationRoute: RouteRecordRaw = {
	name: RouteKeys.VARIABLE_ELEMENTS_VALIDATION,
	path: '/payroll/validation',
	components: {
		default: () => import('./payroll-validation.vue'),
		...defaultLayoutComponents
	},
	children: [VariableElementsReadonlyRoute, PayrollValidationLeaveDaysRoute, PayrollValidationEarnedWageAccessRoute],
	redirect: to => ({ ...VariableElementsReadonlyRoute, params: { type: VariableElementType.BONUSES } }),
	meta: {
		isForbidden: () => {
			const { isAdmin } = storeToRefs(useRolesStore());
			const features = useFeatures();
			return computed(() => Devices().isMobile || !isAdmin.value || !features.hasVariableElementsValidationFeature.value);
		},
		isNavEntry: true,
		label: 'admin.navigation.variable-elements.validation',
		icon: 'dot',
		requiresAuth: true,
		order: 30
	}
};
