import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { useFeatures } from '~/composables';
import { defaultLayoutComponents } from '~/pages/layout';
import { useRolesStore } from '~/stores';

export const PayslipsValidationPendingRoute: RouteRecordRaw = {
	name: 'payslips-validation.pending',
	path: '',
	component: () => import('./components/payslips-validation-pending.vue')
};

export const PayslipsValidationCompleteRoute: RouteRecordRaw = {
	name: 'payslips-validation.complete',
	path: 'complete',
	component: () => import('./components/payslips-validation-complete.vue')
};

export const PayslipsValidationRoute: RouteRecordRaw = {
	name: 'payslips-validation',
	path: '/payslips-validation',
	components: {
		default: () => import('./payslips-validation.vue'),
		...defaultLayoutComponents
	},
	children: [PayslipsValidationPendingRoute, PayslipsValidationCompleteRoute],
	redirect: PayslipsValidationPendingRoute,
	meta: {
		isForbidden: () => {
			const { isAdmin } = storeToRefs(useRolesStore());
			return computed(() => !isAdmin.value || !useFeatures().hasPayslipValidationFeature.value);
		},
		label: 'admin.navigation.payslips-validation',
		icon: 'dot',
		isNavEntry: true,
		order: 31,
		requiresAuth: true
	}
};
