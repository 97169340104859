import { RouteRecordRaw } from 'vue-router';

import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';

import { settingsLayoutComponents } from '../layout';
import { ContractRoute } from './contract-data/contract-data.route';
import { PreferencesRoute } from './preferences/preferences.route';
import { SecurityRoute } from './security/security.route';

export const AccountRoute: RouteRecordRaw = {
	name: RouteKeys.ACCOUNT,
	path: '/account',
	components: {
		default: () => import('./account.vue'),
		...settingsLayoutComponents
	},
	redirect: () => (useRolesStore().hasEmployeeRole ? ContractRoute : SecurityRoute),
	children: [ContractRoute, SecurityRoute, PreferencesRoute]
};
