import { acceptHMRUpdate, defineStore, storeToRefs } from 'pinia';
import { EMPTY, Observable } from 'rxjs';

import { useHttpCache } from '@silae/composables';
import { EmployeeOnboardingDTO, fetchAdministeredOnboardingEmployees$ } from '~/api';

import { useCompaniesStore } from '../companies.store';
import { Clearable } from '../store.domain';

export type AdministeredOnboardingEmployeesStore = Clearable & {
	fetchAdministeredOnboardingEmployees$: (companyId: number, invalidateCache?: boolean) => Observable<Array<EmployeeOnboardingDTO>>;
};

export const useAdministeredOnboardingEmployeesStore = defineStore<
	'administered-onboarding-employee-store',
	AdministeredOnboardingEmployeesStore
>('administered-onboarding-employee-store', () => {
	const { cache$, clearCache } = useHttpCache<number, Array<EmployeeOnboardingDTO>>();
	const { companiesById } = storeToRefs(useCompaniesStore());

	const clear = () => clearCache();

	const isAuthorized = (companyId: number): boolean => {
		const hasThisCompany = companiesById.value.has(companyId);
		return hasThisCompany ? companiesById.value.get(companyId).isAdmin : false;
	};

	const fetchEmployees$ = (companyId: number, invalidateCache?: boolean): Observable<Array<EmployeeOnboardingDTO>> => {
		if (!isAuthorized(companyId)) return EMPTY;

		if (invalidateCache) clearCache();

		return cache$(companyId, fetchAdministeredOnboardingEmployees$(companyId));
	};

	return {
		clear,
		fetchAdministeredOnboardingEmployees$: fetchEmployees$
	};
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useAdministeredOnboardingEmployeesStore, import.meta.hot));
