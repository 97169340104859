import { ThemeDefinition } from 'vuetify';

import { SILAE_KIT_COLORS, SILAE_KIT_VARIABLES } from '@silae/styles';

const light: ThemeDefinition = {
	colors: {
		...SILAE_KIT_COLORS.light,
		'decorative-blue': '#93C7FB',
		'on-decorative-blue': '#00468b',
		selected: '#ECEFF0'
	},
	variables: { ...SILAE_KIT_VARIABLES }
};

const dark: ThemeDefinition = {
	dark: true,
	colors: {
		...SILAE_KIT_COLORS.dark,
		'decorative-blue': '#093867',
		'on-decorative-blue': '#7cbaf8',
		selected: '#ECEFF0'
	},
	variables: { ...SILAE_KIT_VARIABLES }
};

export const vuetifyTheme = {
	themes: {
		dark,
		light
	}
};
