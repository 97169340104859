import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import camelCase from 'lodash/camelCase';
import { App } from 'vue';

import { ICON_LIBRARY } from './icon-library';

// craft icon library
library.add(...Object.values(ICON_LIBRARY));

export const $fa = (name: string) => {
	const iconName = camelCase(`fa-${name}`);
	const iconDef = ICON_LIBRARY[iconName];
	return iconDef != null ? `${iconDef.prefix} fa-${iconDef.iconName}` : null;
};

const setupFontAwesome = (app: App<Element>) => app.component('FontAwesomeIcon', FontAwesomeIcon);

export { setupFontAwesome };
