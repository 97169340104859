import { ComputedRef, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { RouteRecordRaw, useRoute } from 'vue-router';

import { NavigationEntry } from '@silae/components';
import { useLocalBoolean } from '@silae/composables';
import FaIcon from '~/components/icons/fa-icon.vue';

import { useGuardedRoutes } from './router.composables';

const appName = import.meta.env.MS_APP_NAME;
const { value: isCollapsed, toggle: toggleCollapse } = useLocalBoolean(`${appName}-is-collapsed`);

export { isCollapsed, toggleCollapse };

type NavigationEntriesOptions = {
	filterByNavEntry?: boolean;
	filterBySettingsNavEntry?: boolean;
};

export function useNavigationEntries(options: NavigationEntriesOptions = { filterByNavEntry: true }): {
	entries: ComputedRef<Array<NavigationEntry>>;
} {
	const { authorizedRoutes } = useGuardedRoutes();
	const { t } = useI18n();
	const route = useRoute();
	const currentRouteName = computed(() => route.name as string);

	const isActiveRoute = (candidate: RouteRecordRaw, activeRouteName: string): boolean =>
		activeRouteName === candidate.name || candidate.children?.some(childRoute => isActiveRoute(childRoute, activeRouteName));

	const asNavigationEntry = (route: RouteRecordRaw): NavigationEntry => ({
		name: route.name as string,
		label: t(route?.meta?.label),
		icon: {
			component: FaIcon as any,
			props: { icon: route.meta?.icon ?? 'period' }
		},
		active: isActiveRoute(route, currentRouteName.value),
		to: route
	});

	const entries: ComputedRef<Array<NavigationEntry>> = computed(() =>
		authorizedRoutes.value
			.filter(
				route =>
					route.meta?.requiresAuth &&
					((options.filterByNavEntry && route.meta?.isNavEntry) ||
						(options.filterBySettingsNavEntry && route.meta?.isSettingsNavEntry))
			)
			.toSorted((route, other) => route.meta?.order - other.meta?.order)
			.map(asNavigationEntry)
	);

	return { entries };
}
