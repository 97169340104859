import { Observable } from 'rxjs';

import { ADMIN_BASE_URL, COMPANY_BASE_URL, MANAGER_BASE_URL } from '~/api/defs';
import { useBackendHttpService } from '~/api/http';

import { LeaveDaysTypeDTO } from './leave-days-types.dto';

const API = 'leave-days-types';

export function listLeaveDaysTypes$(companyId: number): Observable<Array<LeaveDaysTypeDTO>> {
	const { http } = useBackendHttpService();
	return http.get(`${COMPANY_BASE_URL}/${companyId}/${API}`);
}

export function listLeaveDaysTypesAsManager$(companyId: number, employeeId: number): Observable<Array<LeaveDaysTypeDTO>> {
	const { http } = useBackendHttpService();
	return http.get(`${MANAGER_BASE_URL}/${companyId}/${API}?employeeId=${employeeId}`);
}

export function listLeaveDaysTypesAsAdmin$(companyId: number, employeeId: number): Observable<Array<LeaveDaysTypeDTO>> {
	const { http } = useBackendHttpService();
	return http.get(`${ADMIN_BASE_URL}/${companyId}/${API}?employeeId=${employeeId}`);
}
