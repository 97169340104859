<template>
	<sk-chip :text="getText" :color="getColor" :dot />
</template>

<style lang="scss" scoped></style>

<script lang="ts" setup>
import { computed, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

import { SkChip } from '@silae/components';
import { State } from '~/api';

import { ChipColors, getStateColor } from './simple-chip-colors';

const { t } = useI18n();
const props = defineProps({
  text: null,
  color: { default: ChipColors.NEUTRAL },
  dot: { type: Boolean, default: true }
});
const { color, text } = toRefs(props);

const isTextState = computed(() => {
	return text.value ? Object.values(State).includes(text.value as State) : false;
});

const getText = computed(() => {
	return isTextState.value ? t('common.status.' + text.value) : text.value;
});

const getColor = computed(() => {
	if (isTextState.value) {
		return getStateColor(text.value as State);
	} else {
		return color.value;
	}
});
</script>
