import { DateTime, DateTimeFormatOptions } from 'luxon';

import { ISODateString, ISODateTimeString } from '~/api';

// Custom date range type, instead of using v-calendar's SimpleDateRange (not exported properly)
export type DateRange = { start: Date; end: Date };

export const ISO_FORMAT = 'y-MM-dd'; // ex: 2024-04-01
export const PAY_PERIOD = 'LLLL yyyy'; // ex: avril 2024

export function ISODateTimeAsLocalDate(date: ISODateTimeString, format: DateTimeFormatOptions = DateTime.DATE_FULL): string {
	const dateTime = DateTime.fromISO(date.toString());
	return dateTime.toLocaleString(format);
}

export function ISODateAsLocalDate(ISO: ISODateString, format: DateTimeFormatOptions = DateTime.DATE_FULL): string {
	if (ISO == null) return null;
	return DateTime.fromISO(ISO).toLocaleString(format);
}
export function ISODateAsLocalDateTime(ISO: ISODateString): string {
	if (ISO == null) return null;
	const datetime = DateTime.fromISO(ISO);
	return `${datetime.toLocaleString(DateTime.DATE_SHORT)} ${datetime.toLocaleString(DateTime.TIME_SIMPLE)}`;
}

export function dateAsLocalDate(date: Date, format: DateTimeFormatOptions = DateTime.DATE_FULL): string {
	if (date == null) return null;
	return DateTime.fromJSDate(date).toLocaleString(format);
}

export function asFormattedDate(date: Date | string, format: string): string {
	if (date == null) return null;
	return DateTime.fromISO(date.toString()).toFormat(format);
}

export function dateAsISODate(date: Date): ISODateString {
	if (date == null) return null;
	return DateTime.fromJSDate(date).toISODate();
}

export function splitHoursAndMinutes(hours: number): { hours: number; minutes: string } {
	if (hours == null) return null;
	const totalMinutes = Math.round(hours * 60);
	const hoursPart = Math.floor(totalMinutes / 60);
	const minutesPart = String(totalMinutes % 60).padStart(2, '0');

	return { hours: hoursPart, minutes: minutesPart };
}

export function getDateWithoutTimezone(date: Date): Date {
	if (date == null) {
		return;
	}

	const localTimezoneOffset = date.getTimezoneOffset();
	return new Date(date.getTime() - localTimezoneOffset * 60 * 1000);
}

export function isSameDay(d1: Date, d2: Date): boolean {
	return d1?.getFullYear() === d2?.getFullYear() && d1?.getMonth() === d2?.getMonth() && d1?.getDate() === d2?.getDate();
}
