import isEmpty from 'lodash/isEmpty';
import { acceptHMRUpdate, defineStore, storeToRefs } from 'pinia';
import { ComputedRef, computed, ref } from 'vue';

import { mapBy } from '@silae/helpers';
import { UserCompanyDTO } from '~/api';

import { useCompaniesStore } from './companies.store';
import { useRolesStore } from './roles.store';

export type CompanySelectionStore = {
	adminCompany: ComputedRef<UserCompanyDTO>;
	adminCompanyId: ComputedRef<number>;
	employeeCompany: ComputedRef<UserCompanyDTO>;
	employeeCompanyId: ComputedRef<number>;
	hasSelectedASingleCompany: ComputedRef<boolean>;
	managerCompanies: ComputedRef<Array<UserCompanyDTO>>;
	managerCompaniesIds: ComputedRef<Array<number>>;
	selectAdminCompany: (id?: number) => void;
	selectedCompanies: ComputedRef<Array<UserCompanyDTO>>;
	selectedCompaniesById: ComputedRef<Map<number, UserCompanyDTO>>;
	selectedCompanyIds: ComputedRef<Array<number>>;
	selectEmployeeCompany: (id?: number) => void;
	selectManagerCompanies: (ids?: Array<number>) => void;
};

export const useCompanySelectionStore = defineStore<'company-selection-store', CompanySelectionStore>('company-selection-store', () => {
	const { isEmployee, isManager, isAdmin } = storeToRefs(useRolesStore());
	const {
		employeeCompanies,
		employeeCompaniesById,
		managedCompanies,
		managedCompaniesById,
		administeredCompanies,
		administeredCompaniesById
	} = storeToRefs(useCompaniesStore());

	const _selectedEmployeeCompanyId = ref(null);
	const _selectedManagerCompaniesIds = ref([]);
	const _selectedAdminCompanyId = ref(null);

	const allManagedCompaniesIds = computed(() => managedCompanies.value.map(it => it.companyId));

	const isValidEmployeeCompany = (id: number) => employeeCompaniesById.value.has(id);
	const isValidManagerCompany = (id: number) => managedCompaniesById.value.has(id);
	const isValidAdminCompany = (id: number) => administeredCompaniesById.value.has(id);

	const selectEmployeeCompany = (id?: number) =>
		(_selectedEmployeeCompanyId.value = isValidEmployeeCompany(id) ? id : employeeCompanies?.value?.[0]?.companyId);

	const selectManagerCompanies = (ids?: Array<number>) => {
		const selectedIds = ids?.filter(isValidManagerCompany);
		if (isEmpty(selectedIds)) {
			_selectedManagerCompaniesIds.value = allManagedCompaniesIds.value;
		} else {
			_selectedManagerCompaniesIds.value = selectedIds;
		}
	};

	const selectAdminCompany = (id?: number) =>
		(_selectedAdminCompanyId.value = isValidAdminCompany(id) ? id : administeredCompanies?.value?.[0]?.companyId);

	const employeeCompanyId = computed(() => _selectedEmployeeCompanyId.value);
	const employeeCompany = computed(() => employeeCompaniesById.value.get(_selectedEmployeeCompanyId.value));
	const managerCompaniesIds = computed(() => _selectedManagerCompaniesIds.value);
	const managerCompanies = computed(() => _selectedManagerCompaniesIds.value?.map(id => managedCompaniesById.value.get(id)));
	const adminCompanyId = computed(() => _selectedAdminCompanyId.value);
	const adminCompany = computed(() => administeredCompaniesById.value.get(_selectedAdminCompanyId.value));

	const selectedCompanies = computed(() => {
		if (isEmployee.value) {
			return employeeCompany.value != null ? [employeeCompany.value] : [];
		} else if (isManager.value) {
			return [...managerCompanies.value];
		} else if (isAdmin.value) {
			return adminCompany.value != null ? [adminCompany.value] : [];
		}
	});

	const selectedCompanyIds = computed(() => selectedCompanies.value?.map(it => it.companyId));
	const hasSelectedASingleCompany = computed(() => selectedCompanyIds.value?.length === 1);

	return {
		adminCompany,
		adminCompanyId,
		employeeCompany,
		employeeCompanyId,
		hasSelectedASingleCompany,
		managerCompanies,
		managerCompaniesIds,
		selectAdminCompany,
		selectedCompanies,
		selectedCompaniesById: computed(() => mapBy<number>(selectedCompanies.value, 'companyId')),
		selectedCompanyIds,
		selectEmployeeCompany,
		selectManagerCompanies
	};
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useCompanySelectionStore, import.meta.hot));
