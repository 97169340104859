import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { useFeatures } from '~/composables';
import { defaultLayoutComponents } from '~/pages/layout';
import { Devices } from '~/plugins';
import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';

export const TeamsRoute: RouteRecordRaw = {
	name: RouteKeys.CONFIGURATION_TEAMS,
	path: '/configuration/teams',
	components: {
		default: () => import('./teams.vue'),
		...defaultLayoutComponents
	},
	meta: {
		isForbidden: () => {
			const { isAdmin } = storeToRefs(useRolesStore());
			return computed(() => Devices().isMobile || !isAdmin.value || !useFeatures().hasTeamsAdminFeature.value);
		},
		isNavEntry: true,
		label: 'admin.navigation.teams',
		icon: 'dot',
		requiresAuth: true,
		order: 70
	}
};
