import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { useFeatures } from '~/composables';
import { defaultLayoutComponents } from '~/pages/layout';
import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';

export const PayrollDashboardRoute: RouteRecordRaw = {
	name: RouteKeys.PAYROLL_DASHBOARD,
	path: '/payroll-dashboard',
	components: {
		default: () => import('./payroll-dashboard.vue'),
		...defaultLayoutComponents
	},
	meta: {
		isForbidden: () => {
			const { isAdmin } = storeToRefs(useRolesStore());
			return computed(
				() =>
					!isAdmin.value ||
					!(
						useFeatures().hasVariableElementsBonusesFeature.value ||
						useFeatures().hasVariableElementsHoursFeature.value ||
						useFeatures().hasVariableElementsValidationFeature.value ||
						useFeatures().hasEarnedWageAccessFeature.value ||
						useFeatures().hasPayslipFullCompanyViewFeature.value
					)
			);
		},
		isNavEntry: true,
		label: 'admin.navigation.payroll',
		icon: 'payroll-cycle',
		requiresAuth: true,
		order: 10
	}
};
