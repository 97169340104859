import { Button } from '~/domain';

export type MessageData = {
	actionBtn?: Button;
	alertText?: string;
	alertTitle?: string;
	closeBtnLabel?: string;
	icon?: string;
	isDialog?: boolean;
	isToast?: boolean;
};

export type Message = MessageData & {
	title: string;
	subtitle?: string;
	text?: string;
};

export enum MessageType {
	DIALOG = 'dialog',
	TOAST = 'toast'
}
