import { storeToRefs } from 'pinia';
import { ComputedRef, computed } from 'vue';

import { Feature } from '~/api';
import { useCompaniesStore, useCompanySelectionStore, useRolesStore } from '~/stores';

export function useFeatures(): {
	hasCountersFeature: ComputedRef<boolean>;
	hasEarnedWageAccessFeature: ComputedRef<boolean>;
	hasEmployeeDetails: ComputedRef<boolean>;
	hasEmployeeDetailsRestricted: ComputedRef<boolean>;
	hasFeature: (companyId: number, feature: Feature) => boolean;
	hasLeaveDayAdminFullFeature: ComputedRef<boolean>;
	hasLeaveDaysCreationFeature: ComputedRef<boolean>;
	hasMeetingsAdminFeature: ComputedRef<boolean>;
	hasMeetingsLoginFeature: ComputedRef<boolean>;
	hasOffboardingAdminFeature: ComputedRef<boolean>;
	hasOnboardingAdminFeature: ComputedRef<boolean>;
	hasPayslipFullCompanyViewFeature: ComputedRef<boolean>;
	hasPayslipValidationFeature: ComputedRef<boolean>;
	hasPayslipsFeature: ComputedRef<boolean>;
	hasSalaryTransferFeature: ComputedRef<boolean>;
	hasTeamsAdminFeature: ComputedRef<boolean>;
	hasVariableElementsBonusesFeature: ComputedRef<boolean>;
	hasVariableElementsFeature: ComputedRef<boolean>;
	hasVariableElementsHoursFeature: ComputedRef<boolean>;
	hasVariableElementsValidationFeature: ComputedRef<boolean>;
} {
	const { companiesById } = storeToRefs(useCompaniesStore());
	const { isEmployee, isManager, isAdmin } = storeToRefs(useRolesStore());
	const { employeeCompanyId, selectedCompanyIds, adminCompanyId } = storeToRefs(useCompanySelectionStore());

	const hasFeature = (companyId: number, feature: Feature): boolean => {
		const company = companiesById.value.get(companyId);
		return company ? company.features.includes(feature) : false;
	};

	const hasFeatureInAnyCompany = (ids: Array<number>, feature: Feature): boolean => ids.some(id => hasFeature(id, feature));

	const hasCountersFeature = computed(
		() =>
			isEmployee.value &&
			(hasFeature(employeeCompanyId.value, Feature.LEAVE_DAY_VIEW_CREATE) ||
				hasFeature(employeeCompanyId.value, Feature.LEAVE_DAY_VIEW_READ))
	);

	const hasLeaveDaysCreationFeature = computed(
		() =>
			(isEmployee.value && hasFeature(employeeCompanyId.value, Feature.LEAVE_DAY_VIEW_CREATE)) ||
			(isManager.value && hasFeatureInAnyCompany(selectedCompanyIds.value, Feature.LEAVE_DAY_SUPERVISE))
	);

	const hasPayslipsFeature = computed(() => hasFeature(employeeCompanyId.value, Feature.PAYSLIP_VIEW));

	const hasMeetingsLoginFeature = computed(() => hasFeatureInAnyCompany(selectedCompanyIds.value, Feature.MEETINGS_LOGIN));

	//ADMIN Features
	const hasTeamsAdminFeature = computed(() => isAdmin.value && hasFeature(adminCompanyId.value, Feature.TEAMS_ADMIN));
	const hasLeaveDayAdminFullFeature = computed(() => isAdmin.value && hasFeature(adminCompanyId.value, Feature.LEAVE_DAY_ADMIN_FULL));
	const hasPayslipFullCompanyViewFeature = computed(
		() => isAdmin.value && hasFeature(adminCompanyId.value, Feature.PAYSLIP_FULL_COMPANY_VIEW)
	);
	const hasPayslipValidationFeature = computed(
		() => isAdmin.value && !hasFeature(adminCompanyId.value, Feature.PAYSLIP_NO_VALIDATION) && hasPayslipFullCompanyViewFeature.value
	);
	const hasVariableElementsBonusesFeature = computed(
		() => isAdmin.value && hasFeature(adminCompanyId.value, Feature.VARIABLE_ELEMENTS_BONUSES)
	);
	const hasVariableElementsHoursFeature = computed(
		() => isAdmin.value && hasFeature(adminCompanyId.value, Feature.VARIABLE_ELEMENTS_HOURS)
	);
	const hasVariableElementsValidationFeature = computed(
		() => isAdmin.value && hasFeature(adminCompanyId.value, Feature.VARIABLE_ELEMENTS_VALIDATION)
	);
	const hasMeetingsAdminFeature = computed(() => isAdmin.value && hasFeature(adminCompanyId.value, Feature.MEETINGS_ADMIN));

	const hasSalaryTransferFeature = computed(() => isAdmin.value && hasFeature(adminCompanyId.value, Feature.SALARY_TRANSFER));

	const hasVariableElementsFeature = computed(
		() => hasVariableElementsBonusesFeature.value || hasVariableElementsHoursFeature.value || hasVariableElementsValidationFeature.value
	);
	const hasEarnedWageAccessFeature = computed(
		() => isAdmin.value && hasFeature(adminCompanyId.value, Feature.VARIABLE_ELEMENTS_EARNED_WAGE_ACCESS)
	);

	const hasEmployeeDetails = computed(() => isAdmin.value && hasFeature(adminCompanyId.value, Feature.EMPLOYEE_DETAILS));
	const hasEmployeeDetailsRestricted = computed(
		() => isAdmin.value && hasFeature(adminCompanyId.value, Feature.EMPLOYEE_DETAILS_RESTRICTED)
	);

	const hasOnboardingAdminFeature = computed(() => isAdmin.value && hasFeature(adminCompanyId.value, Feature.ONBOARDING_ADMIN));
	const hasOffboardingAdminFeature = computed(() => isAdmin.value && hasFeature(adminCompanyId.value, Feature.OFFBOARDING_ADMIN));

	return {
		hasCountersFeature,
		hasEarnedWageAccessFeature,
		hasEmployeeDetails,
		hasEmployeeDetailsRestricted,
		hasFeature,
		hasLeaveDayAdminFullFeature,
		hasLeaveDaysCreationFeature,
		hasMeetingsAdminFeature,
		hasMeetingsLoginFeature,
		hasOffboardingAdminFeature,
		hasOnboardingAdminFeature,
		hasPayslipFullCompanyViewFeature,
		hasPayslipValidationFeature,
		hasPayslipsFeature,
		hasSalaryTransferFeature,
		hasTeamsAdminFeature,
		hasVariableElementsBonusesFeature,
		hasVariableElementsFeature,
		hasVariableElementsHoursFeature,
		hasVariableElementsValidationFeature
	};
}
