const separator = '?';

export function stripQueryParams(URL: string, paramsToStrip: Array<string>): string {
	if (URL == null) {
		return;
	}
	const [path, params] = URL.split(separator);
	// we make sure to init URLSearchParams with undefined rather than null
	// otherwise, URLSearchParams will have a query param named null with no value
	const searchParams = new URLSearchParams(params ?? undefined);
	paramsToStrip.forEach(param => searchParams.delete(param));
	return [path, searchParams.toString()].filter(Boolean).join(separator);
}
