import { acceptHMRUpdate, defineStore } from 'pinia';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { useHttpCache } from '@silae/composables';
import {
	AdministeredEmployeeInformationDTO,
	AdministeredEmployeePersonalInformationDTO,
	fetchAdministeredEmployeeInformation$,
	fetchAdministeredEmployeePersonalInformation$,
	fetchAdministeredEmployees$
} from '~/api';
import { AdministeredEmployee } from '~/domain';
import { employeeDtoToEmployee } from '~/utils';

import { Clearable } from '../store.domain';

export type AdministeredEmployeesStore = Clearable & {
	fetchAdministeredEmployees$: (companyId: number, invalidateCache?: boolean) => Observable<Array<AdministeredEmployee>>;
	fetchAdministeredEmployeeInformation$: (
		companyId: number,
		employeeId: number,
		invalidateCache?: boolean
	) => Observable<AdministeredEmployeeInformationDTO>;
	fetchAdministeredEmployeePersonalInformation$: (
		companyId: number,
		employeeId: number,
		invalidateCache?: boolean
	) => Observable<AdministeredEmployeePersonalInformationDTO>;
};

export const useAdministeredEmployeesStore = defineStore<'administered-employee-store', AdministeredEmployeesStore>(
	'administered-employee-store',
	() => {
		const { cache$: cacheAdministeredEmployees$, clearCache: clearAdministeredEmployeesCache } = useHttpCache<
			number,
			Array<AdministeredEmployee>
		>();
		const { cache$: cacheEmployeePersonalInformation$, clearCache: clearEmployeePersonalInformationCache } = useHttpCache<
			string,
			AdministeredEmployeePersonalInformationDTO
		>();
		const { cache$: cacheEmployeeInformation$, clearCache: clearEmployeeInformationCache } = useHttpCache<
			string,
			AdministeredEmployeeInformationDTO
		>();

		const clear = () => {
			clearAdministeredEmployeesCache();
			clearEmployeePersonalInformationCache();
			clearEmployeeInformationCache();
		};

		const fetchEmployees$ = (companyId: number, invalidateCache?: boolean): Observable<Array<AdministeredEmployee>> => {
			if (invalidateCache) clearAdministeredEmployeesCache();

			const fae$ = fetchAdministeredEmployees$(companyId).pipe(map(employees => employees.map(employeeDtoToEmployee)));

			return cacheAdministeredEmployees$(companyId, fae$);
		};

		const fetchEmployeePersonalInformation$ = (
			companyId: number,
			employeeId: number,
			invalidateCache?: boolean
		): Observable<AdministeredEmployeePersonalInformationDTO> => {
			// TODO implement cache invalidation in silae-kit, we should invalidate companyId-employeeId instead of the whole cache
			if (invalidateCache) clearEmployeePersonalInformationCache();

			const cacheKey = `${companyId}-${employeeId}`;
			return cacheEmployeePersonalInformation$(cacheKey, fetchAdministeredEmployeePersonalInformation$(companyId, employeeId));
		};

		const fetchEmployeeInformation$ = (
			companyId: number,
			employeeId: number,
			invalidateCache?: boolean
		): Observable<AdministeredEmployeeInformationDTO> => {
			// TODO implement cache invalidation in silae-kit, we should invalidate companyId-employeeId instead of the whole cache
			if (invalidateCache) clearEmployeeInformationCache();

			const cacheKey = `${companyId}-${employeeId}`;
			return cacheEmployeeInformation$(cacheKey, fetchAdministeredEmployeeInformation$(companyId, employeeId));
		};

		return {
			clear,
			fetchAdministeredEmployees$: fetchEmployees$,
			fetchAdministeredEmployeeInformation$: fetchEmployeeInformation$,
			fetchAdministeredEmployeePersonalInformation$: fetchEmployeePersonalInformation$
		};
	}
);

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useAdministeredEmployeesStore, import.meta.hot));
