import { PotentialMemberDTO, TeamDTO, TeamMemberDTO } from '~/api';
import { PotentialMember, Team, TeamMember, UnassignedMember } from '~/domain';
import { prettyEmployeeName } from '~/utils';

/**
 * Convert a team DTO to a team
 * @param teamDto the team DTO
 */
export function asTeam(teamDto: TeamDTO): Team {
	return {
		id: teamDto.id,
		name: teamDto.name,
		type: teamDto.type,
		manager: asTeamMember(teamDto.manager, teamDto),
		members: teamDto.members.map(teamMemberDto => asTeamMember(teamMemberDto, teamDto))
	};
}

/**
 * Convert a team member DTO and a team DTO to a team member
 * @param teamMemberDto the team member DTO
 * @param teamDTO the team DTO
 */
export function asTeamMember(teamMemberDto: TeamMemberDTO, teamDTO?: TeamDTO): TeamMember {
	return {
		...teamMemberDto,
		fullName: prettyEmployeeName(teamMemberDto.firstName, teamMemberDto.lastName),
		teamId: teamDTO?.id,
		teamName: teamDTO?.name
	};
}

/**
 * Find the team member from the team member within the teams
 * @param unassignedMember
 * @param teams the teams to search in
 */
export function populate(unassignedMember: UnassignedMember, teams: Array<Team>): TeamMember {
	const team = teams.find(team => team.members.some(member => member.uid === unassignedMember.uid));
	return {
		...unassignedMember,
		teamId: team?.id,
		teamName: team?.name
	};
}

export function asPotentialManager(potentialManagerDTO: PotentialMemberDTO): PotentialMember {
	return {
		...potentialManagerDTO,
		fullName: prettyEmployeeName(potentialManagerDTO.firstName, potentialManagerDTO.lastName)
	};
}

export function asPotentialMember(potentialMemberDto: PotentialMemberDTO, teamDTO?: TeamDTO): PotentialMember {
	return {
		...potentialMemberDto,
		fullName: prettyEmployeeName(potentialMemberDto.firstName, potentialMemberDto.lastName),
		teamId: teamDTO?.id,
		teamName: teamDTO?.name
	};
}

export function asUnassignedMember(unassignedMemberDto: TeamMemberDTO): UnassignedMember {
	return {
		...unassignedMemberDto,
		fullName: prettyEmployeeName(unassignedMemberDto.firstName, unassignedMemberDto.lastName)
	};
}
