import { RouteRecordRaw } from 'vue-router';

import { GedRoute } from '~/pages/GED/ged.route';
import { AccountRoute } from '~/pages/account/account.route';
import { AdminAgendaRoute } from '~/pages/admin/agenda/admin-agenda.route';
import { ConfigurationRoute } from '~/pages/admin/configuration/configuration.route';
import { LoginsRoute } from '~/pages/admin/configuration/logins/logins.route';
import { TeamsRoute } from '~/pages/admin/configuration/teams/teams.route';
import { AdminDocumentsRoute } from '~/pages/admin/documents/admin-documents.route';
import { AdminEarnedWageAccessRoute } from '~/pages/admin/earned-wage-access/earned-wage-access.route';
import { EmployeeDetailsRoute } from '~/pages/admin/employees/employee-details/employee-details.route';
import { AdminEmployeeListRoute } from '~/pages/admin/employees/employee-list/employee-list.route';
import { AdminEmployeeLifecycleRoute } from '~/pages/admin/employees/employee-lifecycle/employee-lifecycle.route';
import { AdminEmployeeManagementRoute } from '~/pages/admin/employees/employees.route';
import { AdminLeaveDaysRoute } from '~/pages/admin/leave-days/admin-leave-days.route';
import { PayrollDashboardRoute } from '~/pages/admin/payroll/payroll-dashboard.route';
import { PayrollValidationRoute } from '~/pages/admin/payroll/validation/payroll-validation.route';
import { VariableElementsRoute } from '~/pages/admin/payroll/variable-elements/variable-elements.route';
import { PayslipsValidationRoute } from '~/pages/admin/payslips-validation/payslips-validation.route';
import { AgendaRoute } from '~/pages/agenda/agenda.route';
import { HomeRoute } from '~/pages/home/home.route';
import { LeaveDaysRoute } from '~/pages/leave-days/leave-days.route';
import { MeetingsRoute } from '~/pages/meetings/meetings.route';
import { ConnectionRoute, ImpersonationRoute, SignInRoute } from '~/pages/sign-in/sign-in.route';
import { UpdatePasswordRoute } from '~/pages/update-password/update-password.route';
import { RouteKeys } from '~/router/routes.domain';

const FallbackRoute: RouteRecordRaw = {
	name: RouteKeys.NOT_FOUND,
	path: '/:pathMatch(.*)*',
	redirect: HomeRoute,
	meta: {
		public: true
	}
};

export const routes = [
	AccountRoute,
	AdminAgendaRoute,
	AdminDocumentsRoute,
	AdminEarnedWageAccessRoute,
	AdminEmployeeLifecycleRoute,
	AdminEmployeeListRoute,
	AdminEmployeeManagementRoute,
	AdminLeaveDaysRoute,
	AgendaRoute,
	ConfigurationRoute,
	ConnectionRoute,
	EmployeeDetailsRoute,
	GedRoute,
	HomeRoute,
	ImpersonationRoute,
	LeaveDaysRoute,
	LoginsRoute,
	MeetingsRoute,
	PayrollDashboardRoute,
	PayrollValidationRoute,
	SignInRoute,
	TeamsRoute,
	UpdatePasswordRoute,
	VariableElementsRoute,
	PayslipsValidationRoute,
	FallbackRoute
];
