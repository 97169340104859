import { Observable } from 'rxjs';

import { asQueryParams } from '~/api/api.utils';
import { COMPANY_BASE_URL } from '~/api/defs';
import { useBackendHttpService } from '~/api/http';

import { CreateTeamRequest, PotentialMemberDTO, TeamDTO, TeamMemberDTO, TeamType, UpdateTeamRequest } from './teams.dto';

const TEAMS_API = `teams`;
const TEAM_MEMBERS_API = `team-members`;

export function getAllTeams$(companyId: number): Observable<Array<TeamDTO>> {
	const { http } = useBackendHttpService();
	return http.get(`${COMPANY_BASE_URL}/${companyId}/${TEAMS_API}`);
}

export function getAllUnassignedMembers$(companyId: number, type: TeamType): Observable<Array<TeamMemberDTO>> {
	const { http } = useBackendHttpService();
	const params = asQueryParams({ 'team-type': type });
	return http.get(`${COMPANY_BASE_URL}/${companyId}/${TEAM_MEMBERS_API}/unassigned-members?${params}`);
}

export function getPotentialManagers$(companyId: number, type: TeamType, teamId?: string): Observable<Array<PotentialMemberDTO>> {
	const { http } = useBackendHttpService();
	const params = teamId ? asQueryParams({ 'team-id': teamId, 'team-type': type }) : asQueryParams({ 'team-type': type });

	return http.get(`${COMPANY_BASE_URL}/${companyId}/${TEAM_MEMBERS_API}/potential-managers?${params}`);
}

export function getPotentialMembersForExistingTeam$(
	companyId: number,
	teamId: string,
	managerId: string
): Observable<Array<PotentialMemberDTO>> {
	const { http } = useBackendHttpService();
	const params = asQueryParams({ 'team-id': teamId, 'manager-id': managerId });

	return http.get(`${COMPANY_BASE_URL}/${companyId}/${TEAM_MEMBERS_API}/potential-members?${params}`);
}

export function getPotentialMembersForNewTeam$(
	companyId: number,
	type: TeamType,
	managerId: string
): Observable<Array<PotentialMemberDTO>> {
	const { http } = useBackendHttpService();
	const params = asQueryParams({ 'team-type': type, 'manager-id': managerId });

	return http.get(`${COMPANY_BASE_URL}/${companyId}/${TEAM_MEMBERS_API}/potential-members?${params}`);
}

export function createTeam$(companyId: number, team: CreateTeamRequest): Observable<TeamDTO> {
	const { http } = useBackendHttpService();
	return http.post(`${COMPANY_BASE_URL}/${companyId}/${TEAMS_API}`, team);
}

export function updateTeam$(companyId: number, teamId: string, team: UpdateTeamRequest): Observable<TeamDTO> {
	const { http } = useBackendHttpService();
	return http.put(`${COMPANY_BASE_URL}/${companyId}/${TEAMS_API}/${teamId}`, team);
}

export function deleteTeam$(companyId: number, teamId: string): Observable<void> {
	const { http } = useBackendHttpService();
	return http.delete(`${COMPANY_BASE_URL}/${companyId}/${TEAMS_API}/${teamId}`);
}
