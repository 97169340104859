import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { useFeatures } from '~/composables';
import { defaultLayoutComponents } from '~/pages/layout';
import { Devices } from '~/plugins';
import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';

import { LoginsRoute } from './logins/logins.route';

export const ConfigurationRoute: RouteRecordRaw = {
	name: RouteKeys.CONFIGURATION,
	path: '/configuration',
	components: {
		default: () => import('./configuration.vue'),
		...defaultLayoutComponents
	},
	redirect: () => {
		return { ...LoginsRoute };
	},
	meta: {
		isForbidden: () => {
			const { isAdmin } = storeToRefs(useRolesStore());
			return computed(() => !isAdmin.value || Devices().isMobile || !useFeatures().hasTeamsAdminFeature.value);
		},
		isNavEntry: true,
		label: 'admin.navigation.configuration',
		icon: 'configuration',
		requiresAuth: true,
		order: 50
	}
};
