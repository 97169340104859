import isEmpty from 'lodash/isEmpty';
import { acceptHMRUpdate, defineStore, storeToRefs } from 'pinia';
import { EMPTY, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ComputedRef, Ref, computed, ref } from 'vue';

import { useHttpCache } from '@silae/composables';
import { LeaveDaysTypeDTO, listLeaveDaysTypes$, listLeaveDaysTypesAsAdmin$, listLeaveDaysTypesAsManager$ } from '~/api';
import { LeaveDaysType, asLeaveDayType } from '~/domain';

import { useCompanySelectionStore } from '../company-selection.store';
import { useRolesStore } from '../roles.store';

export type LeaveDaysTypesStore = {
	leaveDaysTypesAsEmployee: ComputedRef<Array<LeaveDaysType>>;
	leaveDaysTypesAsManager: ComputedRef<Array<LeaveDaysType>>;
	leaveDaysTypesAsAdmin: ComputedRef<Array<LeaveDaysType>>;
	fetchLeaveDaysTypesAsEmployee$: (companyId: number) => Observable<Array<LeaveDaysType>>;
	fetchLeaveDaysTypesAsManager$: (companyId: number, employeeId: number) => Observable<Array<LeaveDaysType>>;
	fetchLeaveDaysTypesAsAdmin$: (companyId: number, employeeId: number) => Observable<Array<LeaveDaysType>>;
};

export const useLeaveDaysTypesStore = defineStore<'leave-days-types-store', LeaveDaysTypesStore>('leave-days-types-store', () => {
	const { cache$: cacheEmployee$ } = useHttpCache<number, Array<LeaveDaysTypeDTO>>(60000);
	const { cache$: cacheManager$ } = useHttpCache<string, Array<LeaveDaysTypeDTO>>(60000);
	const { cache$: cacheAdmin$ } = useHttpCache<string, Array<LeaveDaysTypeDTO>>(60000);
	const { isEmployee, isManager, isAdmin } = storeToRefs(useRolesStore());
	const { employeeCompanyId, managerCompaniesIds, adminCompanyId } = storeToRefs(useCompanySelectionStore());

	const _leaveDaysTypesAsEmployee: Ref<Array<LeaveDaysType>> = ref([]);
	const _leaveDaysTypesAsManager: Ref<Array<LeaveDaysType>> = ref([]);
	const _leaveDaysTypesAsAdmin: Ref<Array<LeaveDaysType>> = ref([]);

	const leaveDaysTypesAsEmployee: ComputedRef<Array<LeaveDaysType>> = computed(() => _leaveDaysTypesAsEmployee.value);
	const leaveDaysTypesAsManager: ComputedRef<Array<LeaveDaysType>> = computed(() => _leaveDaysTypesAsManager.value);
	const leaveDaysTypesAsAdmin: ComputedRef<Array<LeaveDaysType>> = computed(() => _leaveDaysTypesAsAdmin.value);

	const fetchLeaveDaysTypesAsEmployee$ = (companyId: number): Observable<Array<LeaveDaysType>> => {
		if (!isEmployee.value || companyId == null || !employeeCompanyId.value) {
			return EMPTY;
		}

		return cacheEmployee$(employeeCompanyId.value, listLeaveDaysTypes$(employeeCompanyId.value)).pipe(
			map(leaveDaysTypesDto => leaveDaysTypesDto.map(asLeaveDayType)),
			tap(leaveDaysTypes => (_leaveDaysTypesAsEmployee.value = leaveDaysTypes))
		);
	};

	const fetchLeaveDaysTypesAsManager$ = (companyId: number, employeeId: number): Observable<Array<LeaveDaysType>> => {
		if (employeeId == null || companyId == null || !isManager.value || isEmpty(managerCompaniesIds.value)) {
			return EMPTY;
		}

		const isManagerOfCompany = managerCompaniesIds.value.some(id => id === companyId);
		if (!isManagerOfCompany) {
			return EMPTY;
		}

		return cacheManager$(`${companyId}-${employeeId}`, listLeaveDaysTypesAsManager$(companyId, employeeId)).pipe(
			map(leaveDaysTypesDto => leaveDaysTypesDto.map(asLeaveDayType)),
			tap(leaveDaysTypes => (_leaveDaysTypesAsManager.value = leaveDaysTypes))
		);
	};

	const fetchLeaveDaysTypesAsAdmin$ = (companyId: number, employeeId: number): Observable<Array<LeaveDaysType>> => {
		if (companyId == null || adminCompanyId.value == null || !isAdmin.value || adminCompanyId.value !== companyId) {
			return EMPTY;
		}

		return cacheAdmin$(`${companyId}-${employeeId}`, listLeaveDaysTypesAsAdmin$(companyId, employeeId)).pipe(
			map(leaveDaysTypesDto => leaveDaysTypesDto.map(asLeaveDayType)),
			tap(leaveDaysTypes => (_leaveDaysTypesAsAdmin.value = leaveDaysTypes))
		);
	};

	return {
		leaveDaysTypesAsEmployee,
		leaveDaysTypesAsManager,
		leaveDaysTypesAsAdmin,
		fetchLeaveDaysTypesAsEmployee$,
		fetchLeaveDaysTypesAsManager$,
		fetchLeaveDaysTypesAsAdmin$
	};
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useLeaveDaysTypesStore, import.meta.hot));
