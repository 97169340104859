import { DateTime } from 'luxon';
import { Observable } from 'rxjs';

import { AdministeredEmployeeInformationDTO } from '~/api';
import { ADMIN_BASE_URL, COMPANY_BASE_URL, ISODateString } from '~/api/defs';
import { useBackendHttpService } from '~/api/http';

import { AdministeredEmployeePersonalInformationDTO } from './employee-personal-information.dto';
import { AdministeredEmployeeDTO, EmployeeDTO, EmployeeOnboardingDTO } from './employees.dto';

const EMPLOYEE_API = 'employees';
const ADMIN_EMPLOYEE_API = 'administered-employees';
export function fetchCompanyEmployees$(
	companyId: number,
	period: ISODateString = DateTime.now().toISODate()
): Observable<Array<EmployeeDTO>> {
	const { http } = useBackendHttpService();
	const URL = `${COMPANY_BASE_URL}/${companyId}/${EMPLOYEE_API}?period=${period}`;
	return http.get(URL);
}

export function fetchAdministeredEmployees$(companyId: number): Observable<Array<AdministeredEmployeeDTO>> {
	const { http } = useBackendHttpService();
	const URL = `${ADMIN_BASE_URL}/${companyId}/${ADMIN_EMPLOYEE_API}`;
	return http.get(URL);
}

export function fetchAdministeredOnboardingEmployees$(companyId: number): Observable<Array<EmployeeOnboardingDTO>> {
	const { http } = useBackendHttpService();
	const URL = `${ADMIN_BASE_URL}/${companyId}/${ADMIN_EMPLOYEE_API}/onboarding`;
	return http.get(URL);
}

export function fetchAdministeredEmployeePersonalInformation$(
	companyId: number,
	employeeId: number
): Observable<AdministeredEmployeePersonalInformationDTO> {
	const { http } = useBackendHttpService();
	const URL = `${ADMIN_BASE_URL}/${companyId}/${ADMIN_EMPLOYEE_API}/${employeeId}/personal-information`;
	return http.get(URL);
}

export function fetchAdministeredEmployeeInformation$(
	companyId: number,
	employeeId: number
): Observable<AdministeredEmployeeInformationDTO> {
	const { http } = useBackendHttpService();
	const URL = `${ADMIN_BASE_URL}/${companyId}/${ADMIN_EMPLOYEE_API}/${employeeId}/information`;
	return http.get(URL);
}
