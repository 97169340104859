import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { useKonamiCode } from '@silae/composables';
import { useFeatures } from '~/composables';
import { defaultLayoutComponents } from '~/pages/layout';
import { Devices } from '~/plugins';
import { RouteKeys } from '~/router/routes.domain';

const { konami } = useKonamiCode();

export const AdminEmployeeLifecycleRoute: RouteRecordRaw = {
	name: RouteKeys.ADMIN_EMPLOYEE_LIFECYCLE,
	path: '/employees/lifecycle',
	components: {
		default: () => import('./employee-onboarding/employee-onboarding.vue'),
		...defaultLayoutComponents
	},
	meta: {
		isForbidden: () => {
			return computed(() => !useFeatures().hasOnboardingAdminFeature.value || Devices().isMobile || !konami.value);
		},
		isNavEntry: true,
		label: 'admin.navigation.employees-lifecycle',
		icon: 'dot',
		requiresAuth: true,
		order: 4
	}
};
